import { FileMimeTypes } from './consts';

// fixes some newline hydration
export function handleNewline(string: string): string {
  return string.replaceAll('\r\n', '\n');
}

const cleanText = (text: string) => text.replace('  ', ' ').trim();

export function getSalaryString(
  from: string | number | null | undefined,
  to: string | number | null | undefined,
  currency?: string,
): string | undefined {
  const { t } = useNuxtApp().$i18n;
  const ft = t('FROM');
  const tt = t('TO');
  const _c = currency ?? '';
  const fromAmount = from;
  const toAmount = to;
  if (fromAmount && toAmount) {
    return fromAmount === toAmount
      ? cleanText(`${fromAmount} ${_c}`)
      : cleanText(`${ft} ${fromAmount} ${_c} ${tt.toLowerCase()} ${toAmount} ${_c}`);
  } else if (fromAmount) {
    return cleanText(`${ft} ${fromAmount} ${_c}`);
  } else if (toAmount) {
    return cleanText(`${tt} ${toAmount} ${_c}`);
  }
}

export const getExtensionsFromMimeTypeArray = (mimeTypes: string[]): string => {
  return mimeTypes
    .map((mimeType) => `.${FileMimeTypes[mimeType as keyof typeof FileMimeTypes]}`)
    .join(', ');
};
