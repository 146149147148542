export const FileMimeTypes: Record<string, string> = Object.freeze({
  'image/png': 'png',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/msword': 'doc',
  'image/jpeg': 'jpg',
  'application/rtf': 'rtf',
  'application/zip': 'zip',
  'application/vnd.oasis.opendocument.text': 'odt',
  'text/html': 'html',
});

export const ALL_ACCEPTED_MIME_TYPES = Object.keys(FileMimeTypes);
export const DEFAULT_HTML_ACCEPT_PARAMETER = ALL_ACCEPTED_MIME_TYPES.join(', ');
